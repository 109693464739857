<template>
  <div class="nestparams">
    <el-row>
      <el-col :span="2"> </el-col>
      <el-button class="iconfont icon-dcicon_save1" type="primary" @click="isSave ? save('form') : edit('form')">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="quit()">取消</el-button>
    </el-row>
    <el-form ref="form" :model="form" label-width="120px">
      <baseSection name="参数组信息">
        <div class="nest_group_params">
          <!-- 参数组选择 -->
          <div class="nest_group">
            <el-col :offset="0" :span="16">
              <el-form-item label="参数组名称:" prop="name" :rules="[{ required: true, message: '请输入嵌套参数组名称', trigger: 'blur' }]">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <div v-for="(ele, ind) in groupIds" :key="ele.groupId">
              <el-col :span="16" v-if="ele.type != 2">
                <el-form-item label="参数组名称" class="nest_group_item">
                  <el-select v-model="groupIds[ind].groupId" placeholder="请选择参数组" @change="changeGroup(ind)">
                    <el-option v-for="item in groupoptions" :disabled="item.disabled" :key="item.groupId" :label="item.name" :value="item.groupId"> </el-option>
                  </el-select>
                  <el-button style="width: 10px" type="text" icon="el-icon-delete" class="nest_group_delbtn" @click="delGroup(ind)"></el-button>
                </el-form-item>
              </el-col>
            </div>
            <el-col :offset="3" :span="15">
              <el-button style="transform: translateX(18px)" class="addGroup" @click="addGroup">+添加参数组</el-button>
            </el-col>
          </div>
          <!-- 参数选择 -->
          <div class="nest_params">
            <el-col :offset="5" :span="16" :pull="9">
              <el-form-item label="参数组代码:" prop="code" :rules="[{ required: true, message: '请输入嵌套参数组代码', trigger: 'blur' }]">
                <el-input v-model="form.code" placeholder="请输入参数代码"></el-input>
              </el-form-item>
            </el-col>
            <div v-for="(ele, ind) in paramIds" :key="ele.paramId">
              <el-col :pull="4" :span="16" v-if="ele.type != 2">
                <el-form-item label="参数名" class="nest_group_item">
                  <el-select v-model="paramIds[ind].paramId" placeholder="请选择参数" @change="changeParams(ind)">
                    <el-option v-for="item in paramoptions" :disabled="item.disabled" :key="item.paramId" :label="item.name" :value="item.paramId"> </el-option>
                  </el-select>
                  <el-button style="width: 10px" type="text" icon="el-icon-delete" class="nest_group_delbtn" @click="delParams(ind)"></el-button>
                </el-form-item>
              </el-col>
            </div>
            <el-col :offset="8" :pull="8">
              <el-button style="transform: translateX(-8px)" class="addParams" @click="addParams">+添加参数</el-button>
            </el-col>
          </div>
        </div>
      </baseSection>
      <baseSection name="上传资料">
        <!-- <div class="edit-params-title"><span> 上传资料</span></div> -->
        <el-row>
          <el-col class="uploadNest" :offset="0" v-if="form.valueType !== 'string'">
            <el-form-item label="参数组图片:">
              <Upload
                :setimgUrl="form.iconUrl"
                :currenttype="5"
                @getimgUrl="
                  item => {
                    form.iconUrl = item;
                  }
                "
                @geticonId="
                  item => {
                    form.iconId = item;
                  }
                "
              ></Upload>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
    </el-form>
    <Drawer
      v-if="drawer"
      :drawer="drawer"
      @closeDrawer="closeDrawer"
      :title="'确认修改'"
      :AlertTitle="'修改内容将同步到以下设备模板和参数组'"
      :TemplateData="TemplateData"
      :NestGroupData="NestGroupData"
      @clickbtn="update()"
    />
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Drawer from '@/components/drawer/index.vue';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  components: {
    Upload,
    Drawer,
    baseSection
  },
  data() {
    return {
      drawer: false,
      isSave: false,
      form: {
        name: '',
        code: '',
        iconId: '',
        iconUrl: ''
      },
      // 选中
      groupIds: [],
      paramIds: [],
      // 下拉
      groupoptions: [],
      paramoptions: [],
      // 模板数据
      TemplateData: [],
      NestGroupData: []
    };
  },
  mounted() {
    if (this.$route.query.data === '[object Object]') {
      this.$router.go(-1);
      return false;
    }
    if (this.$route.query.data) {
      this.isSave = false;
      this.form = this.$route.query.data;
    } else {
      this.isSave = true;
    }
    this.getGroupParamsList();
  },
  methods: {
    async getGroupParamsList() {
      if (this.$route.query.data) {
        const GroupInfoRes = await this.$apis.NestGroup.nestGroupInfo({ groupId: this.$route.query.data.groupId });
        this.groupIds = GroupInfoRes.groupVos;
        this.paramIds = GroupInfoRes.paramVos;
        this.form = GroupInfoRes;
      }
      if (!this.isSave) {
        const Groupres = await this.$apis.NestGroup.grouplist({ groupId: this.$route.query.data.groupId, type: 2 });
        const Paramsres = await this.$apis.NestGroup.paramslist({ groupId: this.$route.query.data.groupId });
        this.groupoptions = Groupres;
        this.paramoptions = Paramsres;
      } else {
        const Groupres = await this.$apis.NestGroup.grouplist({ type: 1 });
        const Paramsres = await this.$apis.NestGroup.paramslist();
        this.groupoptions = Groupres;
        this.paramoptions = Paramsres;
      }
      this.paramoptions.forEach(item => {
        this.paramIds.forEach(ele => {
          if (ele.type !== 2 && item.paramId - 0 === ele.paramId) {
            item.disabled = true;
          }
        });
      });
      this.groupoptions.forEach(item => {
        this.groupIds.forEach(ele => {
          if (ele.type !== 2 && item.groupId - 0 === ele.groupId) {
            item.disabled = true;
          }
        });
      });
    },
    quit() {
      this.$refs.form.resetFields();
      this.$router.go(-1);
    },
    save(formName) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      console.log(code);
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.groupIds === []) {
            this.$message.error('嵌套组创建时必选一个基础组');
            return false;
          }
          const { groupId, name, code, iconUrl, iconId } = this.form;
          const data = {
            groupId,
            name,
            code,
            groupType: 2,
            iconUrl,
            iconId,
            paramIds: this.paramIds,
            groupIds: this.groupIds
          };
          const { fail } = await this.$apis.NestGroup.save(data);
          if (!fail) {
            this.$router.go(-1);
          }
        } else {
          this.$message.error('请正确填写参数组信息');
          return false;
        }
      });
    },
    edit(formName) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.groupIds === []) {
            this.$message.error('嵌套组创建时必选一个基础组');
            return false;
          }
          const res = await this.$apis.basicParamsGroup.previewCite({ groupId: this.form.groupId });
          if (res.deviceParamGroupVoList.length === 0 || res.deviceTemplateVoList === 0) {
            this.update();
          } else {
            this.TemplateData = res.deviceTemplateVoList;
            this.NestGroupData = res.deviceParamGroupVoList;
            this.drawer = true;
          }
        } else {
          this.$message.error('请正确填写参数组信息');
          return false;
        }
      });
    },
    closeDrawer() {
      this.drawer = false;
    },
    async update() {
      const { groupId, name, code, iconUrl, iconId } = this.form;
      const data = {
        groupId,
        name,
        code,
        groupType: 2,
        iconUrl,
        iconId,
        paramIds: this.paramIds,
        groupIds: this.groupIds
      };
      const { fail } = await this.$apis.NestGroup.update(data);
      if (!fail) {
        this.$router.go(-1);
      }
    },
    changeParams(i) {
      if (this.paramIds[i].type === 0) {
        this.paramIds[i].type = 2;
        this.paramIds.splice(i, 0, this.paramIds[i]);
      }
      this.paramIds = JSON.parse(JSON.stringify(this.paramIds));
      this.paramIds[i].type = 1;
      this.paramoptions.forEach(item => {
        if (`${item.paramId}` === `${this.paramIds[i].paramId}`) {
          item.disabled = true;
        }
      });
    },

    changeGroup(i) {
      if (this.groupIds[i].type === 0) {
        this.groupIds[i].type = 2;
        this.groupIds.splice(i, 0, JSON.parse(JSON.stringify(this.groupIds[i])));
      }
      this.groupIds = JSON.parse(JSON.stringify(this.groupIds));
      this.groupIds[i].type = 1;
      this.groupoptions.forEach(item => {
        if (item.groupId === this.groupIds[i].groupId) {
          this.groupIds[i].max = item.max;
        }
        item.disabled = false;
        if (`${item.groupId}` === `${this.groupIds[i].groupId}`) {
          item.disabled = true;
        }

        this.groupIds.forEach(ele => {
          if (ele.type === 1 || ele.type === 0) {
            if (ele.groupId === item.groupId) {
              item.disabled = true;
            }
          }
        });
      });
    },
    addGroup() {
      this.groupIds.push({ type: 1 });
    },
    delGroup(i) {
      let ind = 0;

      this.groupIds.forEach(item => {
        if (item.type === 1 || item.type === 0) {
          ind += 1;
        }
      });
      if (ind <= 1) {
        this.$message.error('嵌套必选一个参数组');
        return false;
      }

      this.groupIds[i].type = 2;
      this.groupoptions.forEach(item => {
        if (`${item.groupId}` === `${this.groupIds[i].groupId}`) {
          item.disabled = false;
        }
      });
    },
    addParams() {
      this.paramIds.push({ type: 1 });
    },
    delParams(i) {
      this.paramIds[i].type = 2;
      this.paramoptions.forEach(item => {
        if (`${item.paramId}` === `${this.paramIds[i].paramId}`) {
          item.disabled = false;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
}
.nest_group_params {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}
.nest_group {
  flex: 1;
}
.nest_params {
  flex: 1;
}
.nest_group_item {
  position: relative;
}
.nest_group_delbtn {
  position: absolute;
  right: -10px;
  transform: translateX(60%);
  width: 90px;
}
.addGroup {
  margin-left: 20px;
}
.addParams {
  margin-left: 20px;
}
.nestparams {
  overflow: hidden;
}
.el-input,
.el-select {
  width: 100%;
}
.uploadNest {
  padding-left: 25px;
}
</style>
